<template>
    <div>
        <ListPanel v-for="(l, index) in lists" :index="index" :key="index" :list="l" />
    </div>
</template>
<script>
import api from '/src/utils/api';

export default {
    components: {
        ListPanel: () => import("/src/components/ListPanel"),
    },
    data() {
        return {
            lists: '',
        };
    },
    computed:{
    },
    mounted() {
        this.fetchLists();
    },
    methods: {
        fetchLists() {
            api.getCall('get-recent-lists').then(lists => {
                // console.log("Recent::lists", lists);
                this.lists = lists;
            });
        },
    },
};
</script>

<style scoped>
</style>